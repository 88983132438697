import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { MainComponent } from './@pages/main/main.component';
import { ReportService } from './shared/services/report.service';
import { ReportViewComponent } from './@pages/report-view/report-view.component';
import { EmbeddedComponent } from './@pages/embedded/embedded.component';
import { BuilderComponent } from './@pages/builder/builder.component';
import { ApplicationEnvironment } from '@compass/environment';

const routes: Routes = [
  { path: 'embedded', component: EmbeddedComponent },
  { path: 'build', redirectTo: 'build/', pathMatch: 'full' },
  {
    path: 'build/:reportCode',
    component: BuilderComponent,
    canMatch: [
      () => {
        return inject(ApplicationEnvironment).isDevelopment;
      },
    ],
  },
  {
    component: MainComponent,
    matcher: url => {
      return {
        consumed: url,
        posParams: {
          revisionCode: new UrlSegment(url[0]?.path.slice(), {}),
          reportId: new UrlSegment(url[1]?.path.slice(), {}),
        },
      };
    },
    // Main can only be activated without valid report
    canMatch: [() => !inject(ReportService).hasReport],
  },
  {
    component: ReportViewComponent,
    matcher: url => {
      if (url.length > 0 && RegExp(/^[a-zA-Z0-9]{30}$/).exec(url[0].path)) {
        return {
          consumed: url,
          posParams: {
            revisionCode: new UrlSegment(url[0].path.slice(), {}),
            reportId: new UrlSegment(url[1]?.path.slice(), {}),
          },
        };
      }

      return null;
    },
    // Main can only be activated with valid report
    canMatch: [() => inject(ReportService).hasReport],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
