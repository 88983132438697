<div class="container-fluid pb-3">
  <div class="row bg-secondary rounded mb-4 py-2">
    <div class="col fw-bold text-score">{{ title || 'Scores' }}</div>

    @if (legend !== 'none') {
      <div class="col-auto text-end text-description d-flex align-items-center">
        @if (
          legend === 'target' || (legend === 'auto' && someItemsHaveTargets())
        ) {
          <span class="dot bg-warning me-1"></span>
          Ideal range
        }

        @if (legend === 'auto' || legend === 'score') {
          <span class="dot bg-primary ms-2 me-1"></span>
          User score
        }
      </div>
    }
  </div>
  <div class="row position-relative" *ngIf="items.length > 0">
    <div class="col">
      <div class="row grid-row position-absolute inset-0">
        <div class="col-12 col-md-5 d-none d-md-block"></div>
        <div class="col-12 col-md-7">
          @if (showChartScale) {
            <cp-rp-chart-scale
              class="h-100"
              [chartValues]="items[0].chartConfig.valueLabels"
              [scaleType]="items[0].chartConfig.chartType" />
          }
        </div>
      </div>
      @for (item of items; track item.measure.measureId) {
        <div
          (click)="showPage(item)"
          (keydown)="showPage(item)"
          [class.hoverable]="item.goToPage"
          tabindex="{{ $index }}"
          class="row rounded chart-row py-2">
          <div
            class="col-12 col-md-5 fw-semibold d-flex text-measure mb-2 mb-lg-0">
            {{ item.measure.label | titlecase }}
          </div>
          <div class="col-12 col-md-7 d-flex align-items-center">
            <cp-rp-measure-score-chart
              [showRangeValues]="false"
              [showRangeTicks]="false"
              [measureId]="item.measure.measureId"
              [tailStart]="item.showTails ? item.tailStart : undefined"
              [tailEnd]="item.showTails ? item.tailEnd : undefined"
              [showValue]="item.showValue" />
          </div>
        </div>
      }
    </div>
  </div>
</div>
