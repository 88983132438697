import { ReportComponent } from '../models/components/report-component';
import { Injectable } from '@angular/core';
import { Validator } from 'fluentvalidation-ts';
import { ReportService } from '../services/report.service';

@Injectable()
export class ComponentValidator extends Validator<ReportComponent> {
  constructor(private readonly reportService: ReportService) {
    super();

    this.ruleFor('measureScope').must({
      predicate: this.validateMeasureScope.bind(this),
      message: scope => `[${scope}] is not a valid measure.`,
    });

    this.ruleForEach('supplementalMeasures').must({
      predicate: this.validateMeasureScope.bind(this),
      message: scope => `[${scope}] is not a valid measure.`,
    });
  }

  private validateMeasureScope(measureId: string): boolean {
    return !!this.reportService.reportData?.assessment.measures.some(
      m => m.measureId === measureId,
    );
  }
}
