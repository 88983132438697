import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { PageService } from '../../services/page.service';
import { Dom, TaskQueue } from '@compass/helpers';
import { AccordionService } from '../../services/accordion.service';

@Component({
  selector: 'cp-rp-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent extends PublicReportComponent implements OnInit {
  @Input()
  name: string = '';

  @Input()
  open: boolean = false;

  get pageId(): string {
    return this.component.id;
  }

  constructor(
    private readonly _pageService: PageService,
    private readonly _accordion: AccordionService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.component.isTopComponent) return;

    TaskQueue.scheduleOnMacroQueue(() => {
      this._pageService.registerPage(this);
    });
  }

  focusMeasure(measureId: string): void {
    Dom.waitFor(`[data-focusable="${measureId}"]`).then(async element => {
      if (!element) return;

      this._accordion.collapseAll();

      await this._accordion.expand(measureId);

      element.scrollIntoView();
    });
  }

  protected isActive(): boolean {
    return this._pageService.activePage?.pageId === this.pageId;
  }
}
