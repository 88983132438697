<div class="d-flex">
  <ngx-monaco-editor
    style="width: 40%; height: 100vh; margin-top: 1rem"
    [(ngModel)]="code"
    [options]="{
      theme: 'vs-dark',
      language: 'html',
      minimap: { enabled: false },
    }" />

  <cp-rp-report-renderer
    *ngIf="reportService.hasReport"
    class="d-block container pb-3 shadow"
    [template]="template$ | async" />
</div>
