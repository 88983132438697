import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Measure } from '../../models/report/measure';
import { PageService } from '../../services/page.service';
import { MeasureService } from '../../services/measure.service';
import {
  ChartConfig,
  ChartConfigProvider,
} from '../../services/chart-config-provider.service';

interface ChartItem {
  measure: Measure;
  goToPage?: string;
  chartConfig: ChartConfig;
  tailStart?: string;
  tailEnd?: string;
  showTails: boolean;
  showValue: boolean;
  targetMeasure?: string;
}

@Component({
  selector: 'cp-rp-measure-chart',
  templateUrl: './measure-chart.component.html',
  styleUrls: ['./measure-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureChartComponent
  extends PublicReportComponent
  implements OnInit
{
  protected items: ChartItem[] = [];
  protected showChartScale: boolean = false;

  @Input()
  showSelf: boolean = false;

  @Input()
  showChildren: boolean = true;

  @Input()
  showValue: boolean = true;

  @Input()
  goToPage?: string;

  @Input()
  title?: string;

  @Input()
  tailStart?: string;

  @Input()
  tailEnd?: string;

  @Input()
  showTails: boolean = true;

  @Input()
  legend: 'auto' | 'score' | 'target' | 'none' = 'auto';

  @Input()
  targetMeasure?: string;

  constructor(
    private readonly _pageService: PageService,
    private readonly _measureService: MeasureService,
    private readonly _chartConfigProvider: ChartConfigProvider,
  ) {
    super();
  }

  ngOnInit(): void {
    this.items =
      this.component.children.length > 0
        ? this.getItemsForChildren()
        : this.getItemsForAllMeasures(this.measureId);

    this.showChartScale = this.areAllItemsTheSamePresentationType();
  }

  protected showPage(item: ChartItem): void {
    if (!item.goToPage) return;

    this._pageService.tryShow(item.goToPage ?? '', item.targetMeasure);
  }

  protected someItemsHaveTargets(): boolean {
    return this.items.some(item =>
      this.reportData.scoring.targets.some(
        target => target.measureId === item.measure.measureId,
      ),
    );
  }

  private areAllItemsTheSamePresentationType(): boolean {
    if (this.items.length < 2) return true;

    let lastPresentationType = this.items[0].measure.scorePresentationType;

    for (const item of this.items) {
      if (item.measure.scorePresentationType !== lastPresentationType) {
        return false;
      }

      lastPresentationType = item.measure.scorePresentationType;
    }

    return true;
  }

  private getItemsForAllMeasures(parentMeasureId: string): ChartItem[] {
    return (
      this._measureService
        .getMeasures(parentMeasureId, this.showSelf)
        .filter(m => this.showChildren || m.measureId === this.measureId)
        // @sonar-ignore-start
        .map(m => {
          return {
            measure: m,
            goToPage: this.goToPage,
            chartConfig: this._chartConfigProvider.getConfig(m.measureId),
            tailStart: this.tailStart || m.tailStart,
            tailEnd: this.tailEnd || m.tailEnd,
            showTails: this.showTails,
            showValue: this.showValue,
            targetMeasure: this.targetMeasure || m.measureId,
          } as ChartItem;
        })
    );
    // @sonar-ignore-end
  }

  private getItemsForChildren(): ChartItem[] {
    const items: ChartItem[] = [];

    for (const child of this.component.children) {
      const measure = this._measureService.getMeasureById(child.measureScope);

      if (!measure || !child.validationStatus.isValid) {
        this.logger.warn(
          `Component ${child.name} is invalid and it will not be rendered.`,
        );
        this.logger.error(child.validationStatus.errors);
        continue;
      }

      // @sonar-ignore-start
      const goToPage = child.getAttributeAsString('goToPage') ?? this.goToPage;
      const showTails =
        child.getAttributeAsBoolean('showTails') ?? this.showTails;
      const tailEnd =
        child.getAttributeAsString('tailEnd') ||
        this.tailEnd ||
        measure.tailEnd;
      const tailStart =
        child.getAttributeAsString('tailStart') ||
        this.tailStart ||
        measure.tailStart;
      const chartConfig = this._chartConfigProvider.getConfig(
        measure.measureId,
      );
      const showValue =
        child.getAttributeAsBoolean('showValue') ?? this.showValue;
      const targetMeasure =
        child.getAttributeAsString('targetMeasure') ||
        this.targetMeasure ||
        measure.measureId;
      // @sonar-ignore-end

      items.push({
        measure,
        goToPage,
        chartConfig,
        tailEnd,
        tailStart,
        showTails,
        showValue,
        targetMeasure,
      });
    }

    return items;
  }
}
