import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '../../shared/services/report.service';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'cp-rp-builder',
  templateUrl: './builder.component.html',
})
export class BuilderComponent implements OnInit {
  private _code?: string;
  private readonly _templateSub = new Subject<string | undefined>();

  protected readonly template$ = this._templateSub
    .asObservable()
    .pipe(debounceTime(300));

  @Input()
  reportCode?: string;

  protected get code(): string | undefined {
    return this._code;
  }

  protected set code(value: string | undefined) {
    this._code = value;

    this._templateSub.next(value);
  }

  constructor(protected readonly reportService: ReportService) {
    this.template$.subscribe(value => {
      this.setTemplateToStorage(value);
    });
  }

  async ngOnInit(): Promise<void> {
    if (!this.reportCode) return;

    await this.reportService.loadReport(this.reportCode);

    let template = this.getTemplateFromStorage();

    if (!template) {
      template = this.reportService.reportData!.completed.reports[0].body;
    }

    this._code = template;
  }

  private getTemplateFromStorage(): string | undefined {
    const value = localStorage.getItem(
      this.createReportTemplateStorageKey(this.reportCode),
    );

    return value ?? undefined;
  }

  private setTemplateToStorage(template: string | undefined): void {
    const key = this.createReportTemplateStorageKey(this.reportCode);
    if (template) {
      localStorage.setItem(key, template);
    } else {
      localStorage.removeItem(key);
    }
  }

  private createReportTemplateStorageKey(reportCode?: string): string {
    return reportCode ? `report-template:${reportCode}` : 'report-template';
  }
}
