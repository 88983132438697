import { Component, Input } from '@angular/core';
import { ReportComponent } from '../../models/components/report-component';
import { PageService } from '../../services/page.service';
import { ReportService } from '../../services/report.service';
import { Logger } from '@compass/logging';

@Component({
  selector: 'cp-rp-report-renderer',
  templateUrl: './report-renderer.component.html',
})
export class ReportRendererComponent {
  private _template?: string;

  protected components: ReportComponent[] = [];

  get template(): string | undefined {
    return this._template;
  }

  @Input()
  set template(value: string | undefined | null) {
    this._template = value === null ? undefined : value;
    this.templateChanged();
  }

  constructor(
    private readonly _pageService: PageService,
    private readonly _reportService: ReportService,
    private readonly _logger: Logger,
  ) {}

  private templateChanged(): void {
    this._logger.debug('Template was set for report');
    this._logger.debug(this.template);

    // Clear all pages first
    this._pageService.clear();

    if (!this.template) {
      this.components = [];
      return;
    }

    const components = ReportComponent.fromTemplate(this.template);

    this.validateComponents(components);

    this.components = components;

    this._logger.debug('Template was parsed for report');
    this._logger.debug([this.components]);
  }

  private validateComponents(components: ReportComponent[]): void {
    if (!this._reportService.reportData) return;

    for (const cmp of components) {
      cmp.validate(this._reportService);

      this.validateComponents(cmp.children);
    }
  }
}
