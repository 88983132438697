/**
 * A class that provides timeout functionality for executing a callback function after a specified period of time.
 * This is a wrapper around the native JS timeout for ease of starting and cancellation
 */
export class Timeout {
  private _timeout?: unknown;

  /**
   * Determines if the timeout is currently running.
   *
   * @returns {boolean} true if the interval is running, false otherwise.
   */
  get isRunning(): boolean {
    return this._timeout !== undefined;
  }

  private constructor(
    private readonly _callback: () => void,
    private readonly _milliseconds: number,
    start: boolean,
  ) {
    if (start) {
      this.start();
    }
  }

  /**
   * Starts the timer
   *
   * @return {void}
   */
  start(): void {
    if (this._timeout) return;

    this._timeout = setTimeout(() => {
      this._timeout = undefined;

      this._callback();
    }, this._milliseconds);
  }

  /**
   * Stops the timer
   *
   * @return {void}
   */
  stop(): void {
    if (!this._timeout) return;

    clearTimeout(this._timeout as never);
    this._timeout = undefined;
  }

  /**
   * Creates a Timeout instance with a given callback to be executed after a specified number of milliseconds.
   *
   * @param {function} callback - The function to be executed after the specified delay.
   * @param {number} milliseconds - The number of milliseconds to delay before executing the callback.
   * @param {boolean} start - Optional. Specifies whether to start the timeout immediately. Default is false.
   * @return {Timeout} - The Timeout object representing the created timeout.
   */
  public static fromMilliseconds(
    callback: () => void,
    milliseconds: number,
    start: boolean = false,
  ): Timeout {
    return new Timeout(callback, milliseconds, start);
  }

  /**
   * Creates a Timeout instance with a given callback to be executed after a specified number of seconds.
   *
   * @param {function} callback - The callback function to be executed.
   * @param {number} seconds - The number of seconds before executing the callback.
   * @param {boolean} [start=false] - Whether to start the timeout immediately. Default is false.
   *
   * @return {Timeout} - The created Timeout instance.
   */
  public static fromSeconds(
    callback: () => void,
    seconds: number,
    start: boolean = false,
  ): Timeout {
    return Timeout.fromMilliseconds(callback, seconds * 1000, start);
  }
}
