import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { ScaleService } from './scale.service';
import { ScalingOptions } from './scaling-options';

/**
 * Directive for setting a scaling source within {@link ScaleService}
 */
@Directive({
  selector: '[cpScaleSource]',
  exportAs: 'cpScaleSource',
})
export class ScaleSourceDirective implements OnDestroy, AfterViewInit {
  /**
   * Specifies whether [data-scale] attribute must
   * be set in order to enable scaling.
   */
  @Input()
  requiresAttributeSet: boolean = false;

  /**
   * Scaling factor from source to target
   */
  @Input()
  scalingFactor: number = 1;

  /**
   * Scaling orientation.
   * Vertical scales with respect to source height and horizontal with respect to source width.
   */
  @Input()
  orientation: 'horizontal' | 'vertical' = 'vertical';

  /**
   * Scale target CSS selector
   */
  @Input()
  targetSelector?: string;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _scalingService: ScaleService,
  ) {}

  ngAfterViewInit(): void {
    this.refresh();
  }

  ngOnDestroy(): void {
    this._scalingService.setSource(null);
  }

  refresh(): void {
    if (
      !this.requiresAttributeSet ||
      (this.requiresAttributeSet &&
        this._elementRef.nativeElement.innerHTML.includes('data-scale'))
    ) {
      const source = this.getScaleSource(this._elementRef.nativeElement);
      this._scalingService.setSource(source.sourceElement, source.options);
    } else {
      this._scalingService.setSource(null);
    }
  }

  private getScaleSource(nativeElement: Element): {
    sourceElement: Element;
    options: ScalingOptions;
  } {
    const sourceElement =
      nativeElement.querySelector('[data-scale]') ?? nativeElement;
    const scalingFactor =
      Number.parseFloat(sourceElement.getAttribute('data-scale') ?? '') ||
      this.scalingFactor;
    const orientation =
      sourceElement.getAttribute('data-scale-orientation') === 'horizontal'
        ? 'horizontal'
        : this.orientation;
    const targetSelector =
      sourceElement.getAttribute('data-scale-target') ?? this.targetSelector;

    return {
      sourceElement,
      options: {
        scalingFactor,
        orientation,
        targetSelector,
      },
    };
  }
}
