import { Injectable } from '@angular/core';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { TaskQueue } from '@compass/helpers';
import { firstValueFrom } from 'rxjs';

/**
 * @class AccordionService
 * @description Service for managing accordion items
 * @inheritdoc
 *
 * @publicApi
 */
@Injectable({
  providedIn: 'root',
})
export class AccordionService {
  private readonly _items: NgbAccordionItem[] = [];

  /**
   * Checks if the instance has any items.
   *
   * @return {boolean} Returns true if the service has at least one accordion registered, otherwise false.
   */
  hasItems(): boolean {
    return this._items.length > 0;
  }

  /**
   * Checks if all items are expanded.
   *
   * @returns {boolean} True if all items are expanded, false otherwise.
   */
  areAllExpanded(): boolean {
    return !this._items.some(i => i.collapsed);
  }

  /**
   * Expands the item with the given ID if it exists.
   *
   * @param {string} itemId - The ID of the item to expand.
   * @return {Promise<void>} Promise that resolves when accordion is exapnded.
   */
  expand(itemId: string): Promise<void> {
    const item = this._items.find(i => i.id === itemId);

    if (!item) {
      return Promise.resolve();
    }

    item.expand();

    return firstValueFrom(item.shown);
  }

  /**
   * Expands all registered accordions.
   *
   * @returns {void}
   */
  expandAll(): void {
    for (const item of this._items) {
      item.expand();
    }
  }

  /**
   * Collapses all registered accordions.
   *
   * @return {void}
   */
  collapseAll(): void {
    for (const item of this._items) {
      item.collapse();
    }
  }

  /**
   * Register items to be monitored.
   *
   * @param {...NgbAccordionItem} items - The NgbAccordionItems to be registered.
   * @return {void}
   */
  register(...items: NgbAccordionItem[]): void {
    TaskQueue.scheduleOnMacroQueue(() => {
      for (const item of items) {
        if (this._items.includes(item)) continue;

        this._items.push(item);
      }
    });
  }

  /**
   * Unregisters the specified accordion items from monitoring.
   *
   * @param {...NgbAccordionItem[]} items - The accordion items to unregister.
   * @returns {void}
   */
  unregister(...items: NgbAccordionItem[]): void {
    TaskQueue.scheduleOnMacroQueue(() => {
      for (const item of items) {
        const index = this._items.indexOf(item);

        if (index < 0) continue;

        this._items.splice(index, 1);
      }
    });
  }
}
