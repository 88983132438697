import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms E.164 number to formatted string.
 */
@Pipe({
  name: 'phone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(value: string, useParenthesis: boolean = true): string {
    if (value?.startsWith('+1')) {
      value = value.substring(2, value.length);
    }
    // If there is no number, or it is not US 10 digits then do nothing
    if (!value || value.length !== 10) return value;

    const areaCode = value.slice(0, 3);
    const middle = value.slice(3, 6);
    const end = value.slice(6, 10);

    return useParenthesis
      ? `(${areaCode})-${middle}-${end}`
      : `${areaCode}-${middle}-${end}`;
  }
}
