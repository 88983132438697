import { Interval } from './interval';
import { Timeout } from './timeout';

/**
 * The Dom class provides utility methods for interacting with the DOM.
 */
export class Dom {
  /**
   * Monitors the DOM for an element matching the given selector and resolves when found.
   *
   * @param {string} selector - The CSS selector for the element to watch.
   * @param {number} [timeoutMs=1000] - The maximum duration to wait for the element in milliseconds.
   * @return {Promise<Element | undefined>} A Promise that resolves to the found element or undefined if the timeout is reached.
   */
  public static waitFor(
    selector: string,
    timeoutMs: number = 1000,
  ): Promise<Element | undefined> {
    return new Promise(resolve => {
      // eslint-disable-next-line prefer-const
      let timeout: Timeout;

      // Create an interval to check for element every N milliseconds
      const interval = Interval.fromMilliseconds(() => {
        const element = document.querySelector(selector);

        // If there is no element break out of here
        if (!element) return;

        // If the element is found stop everything and
        // resolve with the found element.
        timeout?.stop();
        interval.stop();
        resolve(element);
      }, 5);

      // Create a timeout to limit the time to search
      // for the element
      timeout = Timeout.fromMilliseconds(() => {
        // If this fires we have not found anything.
        // Resolve with nothing.
        interval.stop();
        resolve(undefined);
      }, timeoutMs);

      // Start everything
      interval.start();
      timeout.start();
    });
  }
}
